import { FieldsWrapper } from 'site/src/components/atm.wrapper/wrapper.component'
import {
  TextListHeader,
  TextListItem,
  TextListPage,
  TextListPageData
} from 'site/src/components/org.text-list-page/text-list-page.component'
import { ClientDoctorSelectContext } from 'site/src/context/client-doctor-select'
import { PageProps } from 'site/src/utils/local-types'
import { appPaths, getCanonicalUrl } from 'utils/path'
import { getClientAndDoctorUrl } from 'utils/url'
import { Col, Row } from 'atomic'
import { FuseSearch } from 'site/src/components/mol.local-search/fuse-search.component'
import { IndexParams } from 'site/src/components/mol.local-search/fuse-search.utils'
import IndexLayout from 'site/src/components/org.layout/layout.component'
import { CosmicjsInformacoesEstaticas } from 'site/src/data/graphql/graphql-types'
import { graphql } from 'gatsby'
import React from 'react'

export interface DiseaseListTemplateProps {
  items: TextListItem[]
}

const DiseaseListTemplate: React.FunctionComponent<PageProps<
  DiseaseListTemplateProps
>> = props => {
  const staticData = props.data
    .cosmicjsInformacoesEstaticas as CosmicjsInformacoesEstaticas
  const siteUrl = props.data.site.siteMetadata.siteUrl

  const seoData = staticData.metadata.seo

  const [items, setItems] = React.useState(props.pageContext.items)
  const getItems = (startIndex: number, endIndex: number) => {
    return items.slice(startIndex, endIndex) as TextListItem[]
  }

  const pageData: TextListPageData = {
    getItems,
    itemsCount: items.length,
    showItemCount: true,
    title: staticData.metadata.titulo,
    searchPlaceholder: staticData.metadata.buscaPlaceholder,
    col2Title: staticData.metadata.col2Titulo,
    col2ContentHtml: staticData.metadata.col2Conteudo,
    selectPlaceholder: 'Mais buscados',
    seo: {
      siteUrl,
      canonicalUrl: getCanonicalUrl(siteUrl, appPaths.diseaseManual.path),
      title: seoData.titulo,
      description: seoData.descricao,
      imageUrl: seoData.imagem.url,
      imageAlt: seoData.imagemAlternativa
    }
  }
  const [clientPath, doctorPath] = getClientAndDoctorUrl(props.location)

  const handleResult = list => {
    if (list) {
      setItems(list)
    }
  }
  const indexParams: IndexParams<TextListItem> = {
    data: props.pageContext.items,
    keys: [
      {
        name: 'title',
        weight: 1
      }
    ]
  }

  return (
    <ClientDoctorSelectContext.Provider
      value={{ clientUrl: clientPath, doctorUrl: doctorPath }}
    >
      <IndexLayout location={props.location}>
        <TextListPage pageData={pageData} location={props.location}>
          <TextListHeader>
            <Row mb>
              <Col xs={12} md={8}>
                <FieldsWrapper>
                  <FuseSearch
                    id='input-disease-list-search'
                    indexParams={indexParams}
                    onResultChange={handleResult}
                    placeholder={pageData.searchPlaceholder}
                  />
                </FieldsWrapper>
              </Col>
            </Row>
          </TextListHeader>
        </TextListPage>
      </IndexLayout>
    </ClientDoctorSelectContext.Provider>
  )
}

export default DiseaseListTemplate

export const query = graphql`
  query ManualdeDoencasTemplate {
    cosmicjsInformacoesEstaticas(slug: { eq: "manual-de-doencas" }) {
      metadata {
        titulo
        buscaPlaceholder
        col2Titulo
        col2Conteudo
        seo {
          titulo
          descricao
          imagemAlternativa
          imagem {
            url
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
